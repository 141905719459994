<template>
	<div v-if="items.length" v-click-outside="closeLangNav" class="lang-select" @click="toggleLangNav">
		<font-awesome-icon :icon="`fal fa-globe`" size="1x" />
		<span>{{ locale }}&nbsp;</span>
		<font-awesome-icon :icon="`far fa-chevron-down`" size="1x" />
		<div v-if="showLangNav" class="lang-wrapper">
			<div v-for="item in items" :key="`${item.filename}-${item.language}`">
				<nuxt-link class="flag" :class="item.language" :to="`${item.filename}`">
					{{ item.language }}
				</nuxt-link>
			</div>
		</div>
	</div>
</template>

<script setup>
const { locale } = useI18n();

defineProps({
	items: { type: Array, default: () => [] },
});

const showLangNav = ref(false);

const toggleLangNav = () => {
	showLangNav.value = !showLangNav.value;
};

const closeLangNav = () => {
	showLangNav.value = false;
};
</script>

<style lang="scss" scoped>
.lang-select {
	padding: 1px 15px;
	cursor: pointer;
	position: relative;
	color: #fff;
	text-transform: uppercase;

	i {
		font-size: 12px;
	}

	a {
		letter-spacing: 0;
		padding: 5px;

		&:hover {
			text-decoration: none;
		}
	}

	span {
		margin: 0 4px 0 8px;
	}
}

.popup .lang-select {
	color: #000;
}

.lang-wrapper {
	position: absolute;
	top: 35px;
	left: 3px;
	right: 3px;
	padding: 10px 9px;
	text-align: center;
	background: #fff;
	box-shadow: 0 18px 18px rgba(0 0 0 / 10%);
	border-radius: 3px;
	z-index: 90;
}

.lang-wrapper.active,
.lang-wrapper a {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	place-content: space-between flex-start;
	color: #000;
	gap: 5px;
}

.lang-wrapper a {
	width: 100%;
	flex-direction: row;
}

.lang-wrapper img {
	width: 16px;
	height: 16px;
	margin: 2px 0 0;
}
</style>
