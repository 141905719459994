<template>
	<div>
		<div class="fix-on-scroll menu-button nav-buttons" @click.prevent="openMenu">
			<font-awesome-icon :icon="`fal fa-bars`" size="1x" />
			<span>menu</span>
		</div>
		<book-button class="fix-on-scroll book-button nav-buttons reserve-now">
			<font-awesome-icon :icon="`fal fa-calendar-alt`" size="1x" />
			<span class="button-mobile">{{ $t('bookNow') }}</span>
		</book-button>
		<nav class="top-nav">
			<nuxt-link :to="localePath('/')" class="logo" />

			<div class="text-nav">
				<ul class="main-nav">
					<li
						v-if="page"
						v-click-outside="hideLanguageMenu"
						class="language-navigation"
						@click="toggleLanguageMenu"
					>
						<lang-select :items="page.langmenu" />
					</li>
				</ul>
			</div>
			<div class="fullscreen-nav" :class="{ active: showMenu }">
				<div class="menu-close" @click="hideMenu">
					<font-awesome-icon :icon="`fal fa-times`" size="1x" />
				</div>
				<div class="menu-image">
					<picture>
						<source :srcset="defaults[locale].website.introductionImageWebp" type="image/webp" />
						<source :srcset="defaults[locale].website.introductionImage" />
						<img
							class="object-fit"
							:src="defaults[locale].website.introductionImage"
							loading="lazy"
							alt=""
						/>
					</picture>
				</div>
				<div class="menu-section">
					<div class="fullscreen-nav-footer">
						<ul class="main-nav">
							<li
								v-for="item in topmenu[locale]"
								:key="item.filename"
								:class="{ hasnumber: showSpecialOfferCount(item.slug) }"
							>
								<nuxt-link :exact="item.slug === 'index'" :to="`${item.filename}`" @click="hideMenu">
									{{ item.header }}
									<span v-if="showSpecialOfferCount(item.slug)" class="total-packages">
										{{ totalPackageCount }}
									</span>
								</nuxt-link>
							</li>
						</ul>
						<div v-if="page" class="right-section">
							<div class="lang-nav">
								<nuxt-link
									v-for="item in page.langmenu"
									:key="`${item.filename}-${item.language}`"
									class="flag"
									:class="item.language"
									:to="`${item.filename}`"
									@click="hideMenu"
								>
									<span>{{ $t(`${item.language}Long`) }}</span>
								</nuxt-link>
							</div>
							<br />
							<social-media theme="light" :socials="socials" />
						</div>
					</div>
				</div>
			</div>
		</nav>
		<header v-if="!(page && page.headingImage)" class="no-slider" />
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, socials, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const showMenu = ref(false);
const showLanguageMenu = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');
const { data: packages } = await useWebsiteFetch('packages');

const totalPackageCount = packages.value.length;

const toggleLanguageMenu = () => {
	showLanguageMenu.value = !showLanguageMenu.value;
};

const openMenu = () => {
	showMenu.value = true;
	document.body.classList.add('locked');
};

const hideMenu = () => {
	showMenu.value = false;
	document.body.classList.remove('locked');
};

const hideLanguageMenu = () => {
	showLanguageMenu.value = false;
};

const showSpecialOfferCount = (slug) =>
	slug === 'packages-index' && defaults.value[locale.value].packages.showSpecialOfferCount;
</script>

<style lang="scss" scoped>
header.no-slider {
	background: var(--nav-background-color);
	width: 100%;
	height: 90px;
}

.locked .top-nav {
	z-index: 101;
}

.button-mobile {
	font-size: 17px;
	font-weight: 400;
	font-family: var(--heading-font-family);
	vertical-align: middle;
	margin: 1px 0 -1px;
	display: inline-block;
}

.top-nav {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 18;
	padding: 20px 0;
	transition: background 0.3s ease-in-out;

	.logo {
		position: absolute;
		animation: fade-from-top 1s ease;
		inset: 30px 0 auto;
		margin: 0 auto;
		width: 120px;
		height: 168px;
		background: url('~/assets/images/logo-hotel-het-eiland-gold-full.png') no-repeat center center;
		background-size: 120px;
	}

	.row {
		overflow: visible;
	}

	.lang-nav {
		float: left;
		z-index: 5;
		position: relative;
		margin: 0 0 25px;

		img {
			width: 16px;
			float: left;
			margin: 0 5px 0 0;
		}

		a {
			width: 100%;
			float: left;
			color: var(--primary-text-color);
			text-decoration: none;
			margin: 4px 0;
			font-size: 14px;

			&.active {
				color: var(--primary-color);
			}
		}
	}
}

.language-navigation:hover .lang-nav-sel,
.language-navigation:hover .fa-globe {
	color: var(--primary-color);
}

.lang-nav-sel {
	box-shadow: none;
	cursor: pointer;
	width: 30px;
	padding: 0 4px 0 0;
	color: #fff;
	border: none;
	text-transform: uppercase;
	font-size: 12px;
	font-family: sans-serif;
	appearance: none;
	background: url('~/assets/images/dropdown-language.png') right 1px center no-repeat;
	background-size: 7px;
	transition: color 0.3s ease-in-out;

	option {
		color: var(--primary-text-color);
	}

	&::-ms-expand {
		display: none;
	}
}

.lang-select {
	padding: 1px 15px;
	cursor: pointer;
	position: relative;
	color: #fff;

	i {
		font-size: 12px;
	}
}

.language-navigation .fa-globe {
	margin: 0 -5px 0 5px;
	color: #fff;
	transition: color 0.3s ease-in-out;
}

.main-nav {
	list-style: none;
	position: relative;
	z-index: 4;
	text-align: right;

	a {
		color: #fff;
		padding: 5px;
		text-decoration: none;

		&.active {
			color: var(--primary-color);
		}
	}

	li {
		display: inline-block;
		text-transform: uppercase;
		margin: 12px 205px 0 10px;
		letter-spacing: 2px;
		font-size: 13px;

		&.hasnumber {
			position: relative;
			padding-right: 20px;
		}
	}
}

.lang-wrapper {
	position: absolute;
	top: 35px;
	left: 1px;
	right: 1px;
	padding: 10px 9px;
	text-align: center;
	background: #fff;
	box-shadow: 0 24px 24px 0 rgba(0 0 0 / 10%);
	border-radius: 3px;
	z-index: 90;
	display: none;
}

.lang-wrapper.active,
.lang-wrapper a {
	display: flex;
	flex-flow: column nowrap;
	place-content: space-between flex-start;
	color: #000;
	gap: 5px;
	letter-spacing: 1px;
}

.lang-wrapper a {
	width: 100%;
	flex-direction: row;
}

.total-packages {
	position: absolute;
	right: -25px;
	top: -2px;
	width: 21px;
	height: 21px;
	line-height: 21px;
	text-align: center;
	color: #fff;
	background: #bc1b35;
	border-radius: 50%;
	padding-left: 4px;
	font-size: var(--secondary-font-size);
}

.menu-section .total-packages {
	right: -30px;
	top: 3px;
	font-size: var(--body-font-size);
	width: 23px;
	height: 23px;
	line-height: 23px;
}

.nav-buttons,
.menu-close {
	float: right;
	width: 56px;
	height: 56px;
	line-height: 56px;
	text-align: center;
	cursor: pointer;
	font-size: 22px;
	border-radius: 0;
	transition:
		margin 0.4s ease-out,
		opacity 0.4s ease-out,
		top 0.4s ease-out,
		transform 0.4s ease-out;
	margin: 5px 0;

	&.fixed {
		box-shadow: 0 0 5px rgb(0 0 0 / 15%);
	}

	&:hover {
		border-color: #b5b5b5;
	}
}

.menu-close {
	position: absolute;
	top: 10px;
	color: var(--primary-text-color);
	left: 25px;
	z-index: 1;
}

.text-nav {
	z-index: 1;
	position: relative;
}

.top-nav.fixed .text-nav > ul,
.top-nav.fixed .text-nav select,
.noslider .top-nav .text-nav select {
	opacity: 0;
}

.menu-button {
	position: fixed;
	top: 10px;
	opacity: 1;
	z-index: 100;
	color: #fff;
	transition: all 0.3s ease-in-out;
	right: auto;
	left: 25px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	animation: fade-from-left 0.7s ease;

	span {
		font-size: 16px;
		margin: 0 0 3px 10px;
	}

	&.fixed {
		color: var(--primary-text-color);
		background: var(--primary-background-color);
		text-align: center;
		width: auto;
		padding: 0 15px;

		svg {
			margin: 2px auto 0;
		}
	}
}

.noslider {
	.menu-button {
		color: var(--primary-text-color);
		background: var(--primary-background-color);
		text-align: center;
		width: auto;
		padding: 0 15px;

		svg {
			margin: 2px auto 0;
		}
	}

	.lang-select {
		color: var(--primary-text-color);

		i {
			color: var(--primary-text-color);
		}
	}
}

.book-button {
	position: fixed;
	top: 10px;
	opacity: 1;
	z-index: 100;
	color: #fff;
	background: var(--primary-color);
	background: radial-gradient(69.44% 600.34% at 69.44% 23.91%, #ca9945 0%, #997230 100%);
	right: 25px;
	width: auto;
	padding: 0 25px;
	animation: fade-from-right 0.7s ease;
	line-height: 50px;

	svg {
		font-size: 16px;
		margin: 0 15px 0 0;
	}

	&.fixed {
		z-index: 102;
	}

	/* &:hover {
		background: radial-gradient(69.44% 600.34% at 69.44% 23.91%, #516a68 0%, #516a68 100%);
	} */
}

.nav-button-title {
	position: absolute;
	right: 100%;
	width: 0;
	opacity: 0;
	color: #000;
	font-size: var(--secondary-font-size);
}

.nav-buttons:hover .nav-button-title {
	opacity: 1;
	width: auto;
	white-space: nowrap;
	margin-right: 10px;
	transition: all 0.4s ease-out;
}

.fullscreen-nav {
	position: fixed;
	top: 0;
	left: 0;
	background: var(--nav-background-color);
	z-index: 100;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: all 0.5s ease-in-out;
	transform: translate(0%, 100%) matrix(1, 0, 0, 1, 0, 0);

	&.active {
		transform: matrix(1, 0, 0, 1, 0, 0);
		opacity: 1;
	}

	.menu-image {
		float: left;
		background-size: cover;
		background-position: center center;
		width: 38%;
		height: 100vh;
	}

	.menu-section {
		float: left;
		width: 62%;
		padding: 15px 80px 80px;
		position: relative;
		height: 100vh;

		.lang-nav {
			float: left;
			width: 100%;
		}
	}

	.right-section {
		width: 100%;
		position: absolute;
		bottom: 0;
		right: 0;
		padding: 25px 20% 0;
	}

	.socials > a {
		color: var(--primary-text-color);
		border-color: #e6e6e6;
	}

	.main-nav {
		text-align: left;
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		align-items: flex-start;
		width: 100%;
		padding: 25px 20% 0;
		float: left;

		li {
			display: block;
			font-size: 34px;
			margin: 10px 0;
			text-transform: none;
		}

		a {
			color: var(--primary-text-color);
			position: relative;
			font-family: var(--heading-font-family);
			padding: 0 0 2px;
			font-weight: 400;
			letter-spacing: -1px;
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			align-items: center;

			&:hover {
				color: var(--primary-color);
			}

			&.router-link-exact-active {
				color: var(--primary-color);

				&::before {
					display: block;
					content: '';
					margin: 0 20px 0 0;
					width: 36px;
					height: 1px;
					background: var(--primary-color);
				}
			}
		}
	}
}

.fullscreen-nav-footer {
	position: absolute;
	inset: 80px;
}

.cta-fullscreen-menu {
	float: left;
	display: block;
}

.main-nav li a {
	position: relative;
}

@media (min-width: 1281px) {
	.fullscreen-nav .main-nav li {
		font-size: 40px;
	}

	.menu-section .total-packages {
		top: 8px;
	}
}

@media (max-width: 960px) {
	.button-mobile {
		display: initial;
	}

	.nav-buttons:hover,
	.menu-close:hover {
		transform: none;
	}

	.menu-button {
		opacity: 1;
		z-index: 100;
	}

	.menu-button,
	.menu-button.fixed {
		margin-right: -45px;
	}

	.book-button {
		opacity: 1;
		z-index: 102;
		top: auto;
		bottom: 0;
		right: 0;
		width: 100%;
		border-radius: 0;
		margin: 0;

		.fa-calendar-days {
			margin-right: 5px;
			font-size: 18px;
		}

		&.fixed {
			top: auto;
		}
	}

	.text-nav .main-nav {
		display: none;
	}
}

@media (max-width: 850px) {
	.fullscreen-nav {
		.menu-image {
			display: none;
		}

		.menu-section {
			width: 100%;
			overflow-y: scroll;
		}
	}

	.locked .book-button.nav-buttons {
		margin-right: 1px;
	}
}

@media (max-width: 600px) {
	.fullscreen-nav {
		.menu-section {
			padding: 15px 10px 40px 20px;
		}

		.main-nav {
			width: 100%;
			float: left;
			position: relative;
			top: inherit;
			bottom: inherit;

			li {
				font-size: 26px;
			}
		}

		.right-section {
			float: left;
			position: relative;
			margin: 40px 0 0;
			max-width: 100%;
		}
	}

	.fullscreen-nav-footer {
		left: inherit;
		right: inherit;
		top: inherit;
		bottom: inherit;
		position: relative;
		float: left;
		width: 100%;
		margin: 50px 0;
	}

	.menu-section .total-packages {
		top: -1px;
	}
}
</style>
